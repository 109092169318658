
@font-face {
  font-family: "Futura LT Pro";
  src: url("./fonts/AIMEFonts/FuturaLTProXBold.otf")
    format("opentype");
}


@font-face {
  font-family: "Sailec";
  src: url("./fonts/AIMEFonts/SailecRegular.otf") format("opentype");
}



@font-face {
  font-family: "SailecItalic";
  src: url("./fonts/AIMEFonts/SailecRegularItalic.otf") format("opentype");
  font-style: italic;
}



*,
html,
body {
  scroll-behavior: smooth !important;
  font-family: "Sailec";
}


.body {
  margin: auto;
}
.futura {
  font-family: "Futura LT Pro";
}
.carousel{
  min-height:400px;
}

@media screen and (max-width: 420px) {
  .carousel{
    min-height:600px;
  }
}
